import { GET_DASHBOARDS, CREATE_DASHBOARD } from '../actions/types';

export const initialState = {
  dashboards: {
    data: [],
  },
};

export const getDashboards = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARDS:
      return {
        ...state,
        dashboards: action.payload,
      };
    case CREATE_DASHBOARD:
      const newDashboard = {
        ...action.payload.data,
        links: action.payload.links,
      };
      return {
        ...state,
        dashboards: {
          data: [...state.dashboards.data, newDashboard],
        },
      };
    default:
      return state;
  }
};
