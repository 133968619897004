/**
 * Filters an option object by a specified property.
 * @param {Object} option - The option object to filter.
 * @param {string} inputValue - The input value to filter against.
 * @param {string} property - The property name to filter by.
 * @returns {boolean} - True if the property value matches the input, false otherwise.
 */
export const filterByProperty = (option, inputValue, property = 'label') => {
  const targetValue = option?.[property] || ''; // Get the value of the specified property
  const searchValue = inputValue?.toLowerCase() || ''; // Handle undefined or empty input
  return targetValue.toLowerCase().includes(searchValue);
};
